import React from 'react'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization';
import { Link } from 'react-router-dom';

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  // const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <>
    <a  href="https://donutswapreact.maticz.in/swap">
    <Button 
    // onClick={onPresentConnectModal} {...props} 
    
    className="theme-btn">
      {t('Enter App')}
    </Button>
    </a>
    </>
  )
}

export default ConnectWalletButton
